html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}
html,
body,
.root {
  height: 100%;
}
body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  line-height: 1.5;
}
body,
.app {
  background: #f5f5f5;
}

/* CreateContact */

.close-create-contact {
  display: block;
  width: 60px;
  height: 60px;
  background-image: url('../icons/arrow-back.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 30px;
  font-size: 0;
}

.create-contact-form {
  padding: 20px;
  max-width: 500px;
  margin: 0 auto;
  display: flex;
}

.create-contact-details {
  margin-left: 20px;
}

.create-contact-avatar-input {
  min-width: 60px;
  height: 60px;
  background-color: white;
  background-image: url('../icons/person.svg');
  background-size: 32px 32px;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 50%;
}

.create-contact-details input {
  width: 100%;
  padding: 5px 10px;
  margin-bottom: 10px;
  font-size: inherit;
  background: transparent;
  border: none;
  border-bottom: 1px solid #ccc;
  outline: 0;
}

.create-contact-details button {
  margin-top: 20px;
  background: #ccc;
  padding: 10px;
  text-transform: uppercase;
  font-size: inherit;
  border: none;
  outline: 0;
}

/* ListContacts */

.list-contacts {
  padding-top: 60px;
}
.list-contacts-top {
  position: fixed;
  width: 100%;
  top: 0;
  border-bottom: 1px solid #d5d8df;
  display: flex;
}

.search-contacts {
  width: 100%;
  padding: 20px 20px 20px 60px;
  background-image: url('../icons/search.svg');
  background-repeat: no-repeat;
  background-position: 20px center;
  background-size: 1.2em;
  font-size: 1.2em;
  border: 0;
  outline: none;
}

.add-contact {
  display: block;
  width: 73px;
  background: white;
  background-image: url('../icons/person-add.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 28px;
  font-size: 0;
}

.showing-contacts {
  text-align: center;
  margin: 20px 0;
}
.showing-contacts button {
  color: blue;
  background: transparent;
  border: none;
  cursor: pointer;
  font-size: inherit;
}

.contact-list {
  width: 100%;
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.contact-list-item {
  padding: 20px;
  background: white;
  display: flex;
}

@media (min-width: 600px) {
  .contact-list-item {
    margin: 20px;
    border: 1px solid #d5d8df;
    border-radius: 4px;
  }
}

.contact-avatar {
  width: 60px;
  height: 60px;
  margin-right: 20px;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 50%;
  flex-shrink: 0;
}

.contact-details {
  padding-left: 20px;
  border-left: 1px solid #eee;

  flex: 1;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  justify-content: center;
}
.contact-details p {
  margin: 0;
  min-width: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.contact-remove {
  padding-left: 20px;
  align-self: center;
  width: 32px;
  height: 32px;
  background-color: transparent;
  background-image: url('../icons/cancel.svg');
  background-size: 100% 100%;
  border: 0;
  color: black;
  font-size: 0;
  vertical-align: middle;
  cursor: pointer;
  outline: none;
}
